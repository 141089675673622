import { TabContext } from '@mui/lab';
import { Box, Paper, Typography } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import FullScreenDialog from 'src/components/FullScreenDialog';
import { AzureWebPubSubUserContext } from 'src/contexts/AzureWebPubSubUserContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import {
  addUpdateAnalysisHistoryContext,
  closeDialog,
  closeSelection,
  handleInitiateAnalysisBackState,
  setAvailableModels,
  setInitiateAnalysisOpen,
  updateInitiateAnalysisState
} from 'src/redux/slices/docAnalyst';
import { RootState, useSelector } from 'src/redux/store';
import { useLazyGetModelsQuery } from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { DOC_ANALYST_TABS, DOCUMENT_ANALYSIS_STATUS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ContainerWrapper } from '../styles';
import InitiateAnalysis from './Analysis/InitiateAnalysis';
import AnalysisHistory from './Analysis/List/GridView';
import { StatusFormatter } from './Analysis/List/StatusProvider';
import DatasetList from './Dataset/List/GridView';
import QuestionnaireList from './Questionnaire/List/GridView';
import PageHeader from './PageHeader';
import { StyledTabPanel } from './styles';
import CloseConfirmationModal from './utils/CloseConfirmationDialog';

const DocAnalyst = () => {
  const dispatch = useDispatch();

  const {
    initiateAnalysis: {
      isDialogOpen,
      dialogTitle,
      showBackButton,
      selectedAnalysis
    },
    analysisHistoryContexts
  } = useSelector((state: RootState) => state.docAnalyst);

  const { runningAnalysisData } = useContext(AzureWebPubSubUserContext);
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(location?.state?.selectedTab);
  const handleDialogOpen = (value: boolean = true) => {
    dispatch(setInitiateAnalysisOpen(value));
  };
  const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false);

  const [fetchAiModels, { data: aiModels }] = useLazyQuery<
    DataApiInputParams,
    any
  >({
    api: useLazyGetModelsQuery
  });

  useEffect(() => {
    fetchAiModels({ params: undefined });
  }, []);

  useEffect(() => {
    if (aiModels) {
      dispatch(
        setAvailableModels(
          aiModels.data.filter((model) =>
            ['gpt-4o', 'gpt-4-32k'].includes(model.name)
          )
        )
      );
    }
  }, [aiModels]);

  useEffect(() => {
    if (
      !Object.values(DOC_ANALYST_TABS as object).includes(params?.selectedTab)
    ) {
      navigate('/404');
    }
    setSelectedTab(location?.state?.selectedTab || params?.selectedTab);
  }, [location?.state?.selectedTab, params]);

  useEffect(() => {
    setSelectedTab(location?.state?.selectedTab || params?.selectedTab);
  }, [location?.state?.selectedTab, params]);

  const handleDialogClose = useCallback(() => {
    if (selectedAnalysis?.status === DOCUMENT_ANALYSIS_STATUS.INPROGRESS) {
      setIsCloseModalOpen(true);
    } else if (selectedAnalysis?.isQuestionEdited) {
      dispatch(
        updateInitiateAnalysisState({ isUpdateQuestionnaireDialogOpen: true })
      );
    } else {
      handleDialogOpen(false);
      dispatch(closeDialog());
      dispatch(closeSelection());
    }
  }, [selectedAnalysis]);

  const handleBackButton = useCallback(() => {
    dispatch(handleInitiateAnalysisBackState({}));
  }, []);

  const dialogHeaderTitle = useMemo(() => {
    return (
      <Box display={'flex'} gap={'8px'}>
        <Typography fontSize={'26px'} fontWeight={500}>
          {dialogTitle}
        </Typography>
        {selectedAnalysis?.status && (
          <StatusFormatter value={selectedAnalysis?.status} />
        )}
      </Box>
    );
  }, [dialogTitle, selectedAnalysis?.status]);

  useEffect(() => {
    if (
      runningAnalysisData &&
      runningAnalysisData?.status !== DOCUMENT_ANALYSIS_STATUS.INPROGRESS &&
      runningAnalysisData?.status !== DOCUMENT_ANALYSIS_STATUS.REGENERATING
    ) {
      const { id, status } = runningAnalysisData;
      dispatch(
        updateInitiateAnalysisState({
          selectedAnalysis: { ...selectedAnalysis, status }
        })
      );
      dispatch(
        addUpdateAnalysisHistoryContext({
          analysisId: id,
          isAnalysisStreaming: false
        })
      );
    }
  }, [runningAnalysisData]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${T.empowerGPT} - ${t(T.docAnalyst)}`}</title>
      </Helmet>
      <Paper elevation={0} style={{ height: '100%', padding: '0 32px' }}>
        <ContainerWrapper sx={{ height: '100%' }}>
          <TabContext value={selectedTab}>
            <PageHeader
              title={t(T.docAnalyst)}
              showTabs={true}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {selectedTab === DOC_ANALYST_TABS.ANALYSIS && (
              <StyledTabPanel value={DOC_ANALYST_TABS.ANALYSIS}>
                <AnalysisHistory />
              </StyledTabPanel>
            )}
            {selectedTab === DOC_ANALYST_TABS.DATASET && (
              <StyledTabPanel value={DOC_ANALYST_TABS.DATASET}>
                <DatasetList />
              </StyledTabPanel>
            )}
            {selectedTab === DOC_ANALYST_TABS.QUESTIONNAIRE && (
              <StyledTabPanel value={DOC_ANALYST_TABS.QUESTIONNAIRE}>
                <QuestionnaireList />
              </StyledTabPanel>
            )}
          </TabContext>
          <FullScreenDialog
            title={dialogHeaderTitle}
            isOpen={isDialogOpen}
            setIsOpen={handleDialogOpen}
            showBackButton={showBackButton}
            handleBackButton={handleBackButton}
            onClose={handleDialogClose}
          >
            <InitiateAnalysis />
          </FullScreenDialog>
          {analysisHistoryContexts[selectedAnalysis?.id]?.isAnalysisStreaming &&
            isCloseModalOpen && (
              <CloseConfirmationModal
                setIsCloseModalOpen={setIsCloseModalOpen}
                handleDialogOpen={handleDialogOpen}
                isCloseModalOpen={isCloseModalOpen}
              />
            )}
        </ContainerWrapper>
      </Paper>
    </React.Fragment>
  );
};

export default DocAnalyst;
