import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
const deJSON = {
  //General
  [T.empowerGPT]: 'EmpowerGPT',
  [T.knowledgeBaseChat]: 'Wissensdatenbank Chat',
  [T.enterprise]: 'Unternehmen',
  [T.worldKnowledge]: 'Weltwissen',
  [T.generalChat]: 'Allgemeiner Chat',
  [T.languageSwitcher]: 'Sprache wählen',
  [T.logout]: 'Ausloggen',
  [T.profile]: 'Profil',
  [T.uploadImport]: 'Hochladed / Importieren',
  [T.documents]: 'Dokumente',
  [T.conversations]: 'Gespräche',
  [T.valuableInsights]: 'Gewinnen Sie schnell wertvolle Erkenntnisse',
  [T.uploadYourDocuments]: 'Dokumente hochladen.',
  [T.clearFilters]: 'Zurücksetzen',
  [T.resetPassword]: 'Kennwort ändern',
  [T.userGroups]: 'Benutzergruppen',
  [T.userGroupName]: 'Benutzergruppenname',
  [T.accountType]: 'Konto Typ',
  [T.email]: 'Email',
  [T.startChat]: 'Chat starten',
  [T.createdWithTime]: 'vor {{time}} erstellt',
  [T.modifiedWithTime]: 'vor {{time}} geändert',
  [T.syncedWithTime]: 'Synchronisiert vor {{time}}',
  [T.rolesAndPermissions]: 'Rollen und Berechtigungen',
  [T.selectItemsToDelete]: 'Elemente zum Löschen auswählen',
  [T.selectItemsToTag]: 'Elemente zum Markieren auswählen',
  [T.selectItemsToMove]: 'Elemente zum Verschieben auswählen',
  [T.noPermissionToDelete]: 'Keine Berechtigung zum Löschen',
  [T.noPermissionToView]: 'Keine Berechtigung zum Anzeigen',
  [T.noPermissionToMoveHere]: 'Keine Berechtigung zum Verschieben hierher',
  [T.noPermissionToDownload]: 'Keine Berechtigung zum Herunterladen',
  [T.tagDisabledForWorkspaceChat]:
    'Die Funktion "Tags" steht in Arbeitsbereich-Chats nicht zur Verfügung',
  [T.typeToSearch]: 'tippe um zu suchen',
  [T.usage]: 'Verwendung',
  [T.disclaimerEgpt]:
    'Das KI-Modell kann Fehler machen, bitte prüfen Sie dies anhand der angegebenen Zitate.',
  [T.reprocess]: 'Erneut verarbeiten',
  [T.selectItemsToReprocess]: 'Elemente zum erneuten Verarbeiten auswählen',
  [T.chatGPT]: 'ChatGPT',
  [T.fileProcessing]: 'Dateiverarbeitung',
  [T.ok]: 'OK',
  [T.goBack]: 'Zurück',
  [T.settings]: 'Einstellungen',
  [T.llmsSettingsHeading]: 'AI-Modelle verfügbar im Chat',
  [T.selectModels]: 'Modelle auswählen',
  [T.modelsWarning]: 'Si prega di selezionare almeno un modello.',
  [T.version]: 'Version',
  [T.configure]: 'Konfigurieren',
  [T.tenantKnowledgeBase]: '{{tenant}}wissen',
  [T.noInternet]:
    'Keine Internetverbindung. Bitte überprüfen Sie Ihr Internet und versuchen Sie es erneut.',

  //filters

  [T.search]: 'Suchen',
  [T.accountTypeSelect]: 'Wählen Sie den Kontotyp aus',
  [T.selected]: `Ausgewählt`,
  // Documents
  [T.uploadDocs]: 'Dokumente hochladen',
  [T.allDocuments]: 'Alle Dokumente',
  [T.docName]: 'Dokument name',
  [T.uploadedBy]: 'Hochgeladen von',
  [T.uploadedOn]: 'Hochgeladen am',
  [T.actions]: 'Aktionen',
  [T.view]: 'Ansehen',
  [T.share]: 'Teilen',
  [T.sync]: 'Synchronisieren',
  [T.sharedWith]: 'Geteilt mit',
  [T.invite]: 'Einladen',
  [T.details]: 'AI-bevölkerte Details',
  [T.documentType]: 'Dokumenttyp',
  [T.size]: 'Größe',
  [T.length]: 'Länge',
  [T.description]: 'Beschreibung',
  [T.rowsPerPage]: 'Zeilen pro Seite',
  [T.uploadTimeAndDateWithUser]: 'Am {{time}} von {{user}} hochgeladen',
  [T.download]: 'Herunterladen',
  [T.uploadProgress]: 'Upload-Fortschritt',
  [T.document]: 'Dokument',
  [T.docsDropMessage]:
    'Ziehen Sie die Dokumente hierhin, um mit dem Hochladen zu beginnen.',
  [T.uploadDocFailed]: 'Fehler beim Hochladen der Dokumente',
  [T.uploadDocsFailed]: 'Fehler beim Hochladen der Dokument',
  [T.uploadMessage]: 'Sie haben {{count}} Dokumente hochgeladen.',
  [T.dropDocsMessage1]:
    'Ziehen Sie die Dokumente hierhin, um mit dem Hochladen zu beginnen.',
  [T.dropDocsMessage2]: 'Dokumente hierher ziehen und ablegen.',
  [T.dropDocsRestriction]: 'Sie können diese Dokumenttypen nicht hochladen.',
  [T.dropDocsPartialRestriction]:
    'Einige ungültige Dokumenttypen wurden abgelehnt',
  [T.uploadDocSuccess]: '{{count}} Dokument erfolgreich hochgeladen.',
  [T.uploadDocsSuccess]: '{{count}} Dokument(e) erfolgreich hochgeladen.',
  [T.uploadDocsWarningMessage]:
    '{{count}} Dokument(e) erfolgreich hochgeladen und {{failed}} sind fehlgeschlagen. Wiederholen...',
  [T.documentsUploadSuccess]: 'Dokumente erfolgreich hochgeladen.',
  [T.failedFetchingDocuments]: 'Dokumente konnten nicht abgerufen werden',
  [T.searchDocuments]: 'Dokumente durchsuchen',
  [T.tagDocuments]: 'Tag-Dokumente',
  [T.docTaggedSuccess]: 'Dokumente erfolgreich getaggt!',
  [T.docTaggedError]: 'Fehler beim Taggen der Dokumente!',
  [T.docTagsUpdatedSuccess]: 'Tags erfolgreich aktualisiert!',
  [T.docTagsUpdatedError]: 'Tags konnten nicht aktualisiert werden!',
  [T.docTagsDeleteSuccess]: 'Tags erfolgreich gelöscht!',
  [T.docTagsDeleteError]: 'Tags konnten nicht gelöscht werden!',
  [T.enterTagName]: 'Geben Sie den Tag-Namen ein.',
  [T.selectTags]: 'Auswählen von Tags',
  [T.selectedTags]: 'Ausgewählte Tags',
  [T.tags]: 'Schlagwörter', //'Tags also available'
  [T.selectUploadTags]:
    'Wählen Sie Tags aus, um sie auf hochgeladene Dokumente anzuwenden',
  [T.maxDocSize]:
    'Die Dokumentenname-Länge von "{{ name }}" überschreitet das maximale Limit von 200 Zeichen.',
  [T.uploaded]: 'Hochgeladen',
  [T.modifiedBy]: 'Geändert von',
  [T.syncedBy]: 'Synchronisiert von',
  [T.syncedOn]: 'Synchronisiert am',
  [T.syncing]: 'Wird gerade synchronisiert...',
  [T.syncingInGridView]:
    'Bitte warten, während der Ordner synchronisiert wird...',
  [T.syncingFailed]: 'Synchronisierungsversuch fehlgeschlagen',
  [T.modifiedOn]: 'Geändert am',
  [T.status]: 'Status',
  [T.documentDeletionFailed]: 'Dokument konnte nicht gelöscht werden',
  [T.documentNotEmptyError]:
    'Das Löschen eines nicht leeren Ordners ist nicht erlaubt.',
  [T.uploadFolder]: 'Ordner hochladen',
  [T.documentDeleteSuccess]: 'Dokument erfolgreich gelöscht.',
  [T.documentsDeleteSuccess]: 'Dokumente erfolgreich gelöscht.',
  [T.documentDeleteConfirmation]:
    'Sind Sie sicher, dass Sie das Dokument löschen möchten?',
  [T.collectionDeleteSuccess]: 'Sammlung erfolgreich gelöscht!',
  [T.collectionDeleteConfirmation]:
    'Möchten Sie die Sammlung wirklich löschen?',
  [T.collectionDeletionFailed]: 'Löschen der Sammlung fehlgeschlagen.',
  [T.workspaceDeletionFailed]: 'Löschen des Arbeitsbereichs fehlgeschlagen.',
  [T.workspaceDeleteSuccess]: 'Arbeitsbereich erfolgreich gelöscht!',
  [T.workspaceDeleteConfirmation]:
    'Möchten Sie den Arbeitsbereich wirklich löschen?',
  [T.multipleDocumentDeleteConfirmation]:
    'Sind Sie sicher, dass Sie die ausgewählten Dokumente löschen möchten?',
  [T.genericConfirmation]: 'Sind Sie sich sicher?',
  [T.retrialFailed]:
    'Das Hochladen einiger Dokumente ist fehlgeschlagen. Bitte versuchen Sie es später erneut.',
  [T.deleteDocuments]: 'Dokumente löschen',
  [T.nestedFolderDelete]:
    'Diese Aktion löscht alle ausgewählten Ordner, Unterordner und Dateien. Möchten Sie fortfahren?',
  [T.multipleFilesReprocessConfirmation]:
    'Sind Sie sicher, dass Sie das ausgewählte Dokument erneut verarbeiten möchten?',
  [T.cancelUploadConfirmation]:
    'Möchten Sie das Hochladen der verbleibenden Dateien wirklich abbrechen?',
  [T.retryFailedDocuments]:
    'Versuchen Sie es erneut mit fehlgeschlagenen Dokumente',
  [T.processingFailed]: 'Verarbeitung fehlgeschlagen',
  [T.processLastTransaction]: 'Letzte Transaktion verarbeiten',
  [T.processAllTransactions]: 'alle Transaktionen abwickeln',
  [T.documentDownloadError]:
    'Das Dokument konnte nicht heruntergeladen werden.',
  [T.destinationFolder]: 'Wählen Sie den Zielordner',
  [T.move]: 'Bewegen',
  [T.moveDuplicateError]: 'Datei/Ordner existiert bereits.',
  [T.moveSuccess]: 'Datei/Ordner erfolgreich verschoben.',
  [T.moveFailed]: 'Fehler beim Verschieben von Datei/Ordner.',
  [T.reprocessSuccess]:
    'Dateien erfolgreich für erneute Verarbeitung in die Warteschlange gestellt.',
  [T.reprocessFailed]: 'Fehler beim erneuten Verarbeiten der Dateien.',
  [T.reprocessCountInfo]: 'Erneutes Verarbeiten von {{count}} Dateien.',
  [T.syncSuccess]: 'Ressource erfolgreich in die Warteschlange gestellt.',
  [T.syncFailed]: 'Fehler beim Synchronisieren der Ressource.',
  [T.nameCharacterLengthError]:
    'Der Name sollte zwischen 1 und 200 Zeichen liegen.',
  [T.name]: 'Name',
  [T.resourceSharingSuccessMessage]: 'Ressource erfolgreich geteil.',
  [T.resourceSharingErrorMessage]: 'Fehler beim Teilen der Ressource.',
  [T.userOrUserGroup]: 'Benutzer oder Benutzergruppe',
  [T.searchForUserOrUserGroup]: 'Suche nach Benutzer oder Benutzergruppe',
  [T.message]: 'Nachricht',
  [T.addMessage]: 'Nachricht hinzufügen',
  [T.sendEmailNotificationToRecipients]:
    'E-Mail-Benachrichtigung an Empfänger senden',
  [T.advanced]: 'Erweitert',
  [T.keepManuallyAssignedRolesOnSubFoldersFiles]:
    'Die bereits zugewiesenen Rollen in Unterordnern/Dateien beibehalten',
  [T.assignRoleToAllSubFoldersFiles]:
    'Rolle allen Unterordnern/Dateien zuweisen',
  [T.assignRoleOnFilesOnly]: 'Rolle nur auf Dateien zuweisen',
  [T.noUserOrUserGroupHasAccessToResource]:
    'Kein Benutzer oder Benutzergruppe hat Zugriff auf die Ressource.',
  [T.noMatchingUsersOrUserGroupsFound]:
    'Keine übereinstimmenden Benutzer oder Benutzergruppen gefunden',
  [T.controllerRoleAssignmentToRegularUserAtWorkspace]:
    'Die ausgewählte Rolle kann nicht regulären Benutzern in Arbeitsbereichen zugewiesen werden.',
  [T.regularUserAssignmentToWorkspace]:
    'Normale Benutzer können nicht als Arbeitsbereichs-Controller ernannt werden. Befördere den Kontotyp von der Benutzerseite aus.',
  [T.contollerAssignmentError]:
    'Nicht berechtigt, Benutzer als Controller in Arbeitsbereichen einzuladen.',
  [T.authorizedToRemoveLowTierAccountTypesOnly]:
    'Berechtigt, Zuweisungen für Kontotypen niedrigerer Stufe zu entfernen.',
  [T.containsCollection]: 'Enthält: {{count}} Sammlungen',
  [T.containsItems]: 'Enthält: {{count}} Elemente',
  [T.createPermissionDenied]: 'Berechtigung zum Erstellen nicht gefunden',
  [T.uploadPermissionDenied]: 'Berechtigung zum Hochladen nicht gefunden',
  [T.createCollection]: 'Erstellen Sie eine Sammlung',
  [T.collectionPlaceholder]:
    'Organisieren Sie Dokumente zu ähnlichen Themen innerhalb einer Sammlung',
  [T.createFolder]: 'Erstellen Sie einen Ordner',
  [T.folderPlaceholder]:
    'Fügen Sie Inhalte hinzu, um Ihr Wissensgebiet aufzubauen',
  [T.assignViewerSubtitle]: 'Abfragen und den Inhalt lesen.',
  [T.assignControllerSubtitle]:
    'Abfragen, bearbeiten und den Zugriff auf den Inhalt verwalten.',
  [T.assignContributorSubtitle]: 'Abfragen, lesen und zum Inhalt hinzufügen.',
  [T.emptyWorkspace]: 'Kein Arbeitsbereich verfügbar',
  [T.emptyCollection]: 'Keine Sammlung verfügbar',
  [T.workspaces]: 'Arbeitsbereiche',
  [T.collections]: 'Sammlungen',
  [T.licenseLimitReached]: 'Lizenzlimit erreicht',
  [T.author]: 'Autor',
  [T.documentDetailsDisclaimer]: `Die von AI generierten Angaben können falsch sein.`,

  // Chat
  [T.Chat]: 'Plaudern',
  [T.chat]: 'plaudern',
  [T.createNewChat]: 'Neuer Chat',
  [T.send]: 'Schicken',
  [T.queryPlaceholder]: 'Fragen Sie.',
  [T.queryPlaceholderWorldKnowledge]:
    "Fragen. Drücken Sie '/', um auf nützliche Eingabeaufforderungen zuzugreifen.",
  [T.chatConfigurations]: 'Chat-Konfiguration',
  [T.parameterDialogTitle]: 'Parameter',
  [T.parameterDialogSubtitle]:
    'Aktualisieren Sie Chat-Parameter, um effizient nach Dokumenten zu suchen',
  [T.updateParameters]: 'Parameter aktualisieren',
  [T.cancel]: 'Abbrechen',
  [T.submit]: 'Senden',
  [T.save]: 'Speichern',
  [T.confirm]: 'Bestätigen',
  [T.add]: 'Hinzufügen',
  [T.parametersUpdateSuccess]: 'Chat-Parameter erfolgreich aktualisiert!',
  [T.parametersUpdateError]: 'Fehler beim Aktualisieren der Chat-Parameter!',
  [T.temperature]: 'Temperatur',
  [T.tokens]: 'Maximale Länge (Token)',
  [T.sequences]: 'Stop-Sequenzen',
  [T.probabilities]: 'Top-Wahrscheinlichkeiten',
  [T.frequency]: 'Frequenzstrafe',
  [T.presence]: 'Präsenzstrafe',
  [T.chatRenameSuccess]: 'Chat erfolgreich umbenannt!',
  [T.chatRenameFailed]: 'Fehler beim Umbenennen des Chats!',
  [T.chatDeleteSuccess]: 'Chat erfolgreich gelöscht!',
  [T.chatDeleteFailed]: 'Fehler beim Löschen des Chats!',
  [T.generatingResponse]: 'Generiere Antwort',
  [T.errorGeneratingResponse]:
    'Konnte keine Antwort generieren! Bitte versuchen Sie es erneut.',
  [T.chatGptSlogan]:
    'Unterstützung bei der Erstellung von Inhalten, der Kodierung, der Recherche und dem Zugang zum Weltwissen.',
  [T.empowerGptSlogan]:
    'Für Antworten im Lichte der zugänglichen Wissensdatenbank.',
  [T.messagesLoadingFailed]:
    'Fehler beim Abrufen der Nachrichten. Bitte versuchen Sie es erneut!',
  [T.responseFailedMessage]:
    'Es ist ein Fehler bei der Generierung einer Antwort aufgetreten.',
  [T.responseStreamingFailedMessage]:
    'Es gab einen Fehler in der Streaming-Antwort. Bitte aktualisieren Sie die Seite.',
  [T.regenerateResponse]: 'Antwort erneut generieren',
  [T.errorAddingReaction]: 'Fehler beim Hinzufügen der Reaktion.',
  [T.errorUpdatingChatContext]: 'Fehler beim Aktualisieren des Chat-Kontexts.',
  [T.chatDeleteConfirmation]:
    'Sind Sie sicher, dass Sie den Chat löschen möchten?',
  [T.samplePrompts]: 'Beispielhafte Anfragen',
  [T.capabilities]: 'Fähigkeiten',
  [T.limitations]: 'Einschränkungen',
  [T.samplePrompt1]:
    'Welche Prüfungen sollte ich vor der Installation der Etaprime L-Pumpe durchführen?',
  [T.samplePrompt2]:
    'Welcher zulässige Viskositätsbereich hat Rohöl für das L27/38S Kraftwerk?',
  [T.samplePrompt4]:
    'Richtlinien für die Erstellung einer Pivot-Tabelle in Microsoft Excel.',
  [T.samplePrompt5]:
    'Tipps zur Zusammenarbeit und Kommunikation in Remote-Teams.',
  [T.samplePrompt7]:
    'Vorschläge für Optimierungsstrategien in der Lieferkette.',
  [T.samplePrompt8]:
    'Best Practices zur Identifizierung von Unternehmensrisiken.',
  [T.capabilities1]:
    'Erhalte Antworten auf deine Fragen ausschließlich basierend auf den hochgeladenen Dokumenten.',
  [T.capabilities2]:
    'Behält den Kontext der vorherigen Fragen innerhalb eines Chats bei.',
  [T.limitations1]:
    'Kann Schwierigkeiten haben, Attribute zu vergleichen, die über die Datenbank verteilt sind.',
  [T.limitations2]:
    'Wissen beschränkt auf den Umfang der hochgeladenen Dokumente.',
  [T.stickToDocs]: 'Begrenzt die Suche auf im Viewer geöffnete Dokumente.',
  [T.includeChatHistory]:
    'Klicken Sie, um die Chat-Kontextverbindung zu trennen.',
  [T.excludeChatHisory]:
    'Klicken Sie, um den Kontext der letzten Abfrage erneut herzustellen.',
  [T.resourceUnavailable]: 'Ressource nicht verfügbar',
  [T.referenceDocumentDeleted]: 'Referenzdokument gelöscht',
  [T.stickDocMessage]:
    'Begrenzt die Suche auf im Viewer geöffnete Dokumente. (Keine Dokumente geöffnet)',

  [T.disclaimerChatgpt]:
    'AI Model kann Fehler machen, bitte überprüfen Sie wichtige Informationen.',

  [T.copy]: 'Kopieren',
  [T.copied]: 'Kopiert',
  [T.errorCopyingResponse]: 'Fehler beim Kopieren der Antwort',
  [T.contentFilteringErrorMsg]: `Die Antwort wurde gefiltert, weil die Eingabeaufforderung die Azure-Richtlinie zur Inhaltsverwaltung auslöst. Bitte ändern Sie Ihren Prompt und versuchen Sie es erneut.`,
  [T.generalErrorMsg]: `Das KI-Modell war nicht in der Lage, eine Antwort zu generieren. Bitte versuchen Sie es erneut.`,
  [T.shareChatTitle]: `Diesen Chat teilen`,
  [T.shareChatText]: `EmpowerGPT-Nutzer mit dem Link`,
  [T.shareChatDisclaimer]:
    'Alle Nachrichten, die Sie nach der Freigabe hinzufügen, bleiben privat.',
  [T.copyLink]: 'Link kopieren',
  [T.errorCopyingLink]: 'Fehler beim Kopieren des Links',
  [T.sharedBy]: 'Geteilt von: ',
  [T.chatInitiationError]: `Konnte keine Anfrage initiieren. Bitte überprüfen Sie, ob die Modellkonfiguration korrekt ist.`,
  [T.unsupportedLanguage]: `Die Eingabesprache wird derzeit für den Unternehmenswissen chat nicht unterstützt. Unterstützte Sprachen sind Englisch, Deutsch und Italienisch.`,
  [T.rateLimit]:
    'Ihre Organisation hat das Limit für die Anzahl der Anfragen pro Minute erreicht. Bitte versuchen Sie es später noch einmal.',
  [T.webSearchDescription]: 'Durchsucht das Internet nach Antworten',
  [T.stickToDoc]: 'An die Dokumente halten',
  [T.webSearch]: 'Suche im Internet',

  // workspace
  [T.workspaceName]: 'Arbeitsbereichs name',
  [T.collectionName]: 'Sammlungs name',
  [T.workspaceColor]: 'Arbeitsbereichsfarbe',
  [T.workspaceController]: 'Arbeitsbereichs-Controller',
  [T.createWorkspace]: 'Arbeitsbereich erstellen',
  [T.updateWorkspace]: 'Arbeitsbereich aktualisieren',
  [T.newWorkspace]: 'Neuer Arbeitsbereich',
  [T.newCollection]: 'Neue Sammlung',
  [T.newFolder]: 'Neuer Ordner',
  [T.documentUpdateConfirmation]:
    'Arbeitsbereich umbenennen und Hintergrundfarbe aktualisieren',
  [T.backgroundColor]: 'Hintergrundfarbe',
  [T.updatesOn]: 'Aktualisierung',
  [T.updatedBy]: 'Aktualisiert von',
  [T.workspaceUpdateSuccess]: 'Arbeitsbereich erfolgreich aktualisiert!',
  [T.workspaceUpdateFailed]: 'Fehler beim Aktualisieren des Arbeitsbereichs!',
  [T.sortBy]: 'Sortiere nach',
  [T.lastUpdated]: 'Letzte Aktualisierung',
  [T.ago]: 'vor',
  [T.day]: 'Tag',
  [T.days]: 'Tage',
  [T.hour]: 'Stunde',
  [T.hours]: 'Std.',
  [T.min]: 'Minute',
  [T.mins]: 'Protokoll',
  [T.sec]: 'zweite',
  [T.secs]: 'Sekunden',
  [T.publicDisclaimer]:
    'Öffentliche Inhalte sind für alle Benutzer zugänglich.',
  [T.privateDisclaimer]:
    'Die Inhalte in Mein Arbeitsbereich sind nur für Sie zugänglich.',
  [T.moreActions]: 'Weitere Aktionen',

  // Chat Steps
  [T.understandingInput]: 'Verstehen des Inputs',
  [T.searchingContent]:
    'Durchsuchen der Arbeitsbereiche nach relevanten Inhalten',
  [T.searchingWeb]: 'Durchsuchen des Internets nach relevanten Inhalten',

  // Chat feedback ...
  [T.feedbackTitle]: 'Zusätzliches Feedback geben',
  [T.feedback1]: 'Die Antwort zitiert das falsche Dokument.',
  [T.feedback2]:
    'Die Antwort ist außerhalb des Geltungsbereichs der Dokumente.',
  [T.feedback3]: 'Die Antwort ist schädlich/unsicher.',
  [T.feedback4]: 'Die Antwort ist nicht hilfreich.',
  [T.submitFeedback]: 'Feedback senden',
  [T.sources]: 'Quellen',
  [T.feedbackCommentPlaceholder]:
    'Was ist mit der Antwort schiefgelaufen? Wie kann sie verbessert werden?',
  [T.feedbackValidationError]: 'Bitte wählen Sie mindestens 1 Option aus.',
  [T.createAWorkspace]: 'Erstellen Sie einen Arbeitsbereich',
  [T.newWorkspaceDescription]:
    'Erstellen Sie eine separate Wissensbasis für jede Abteilung',
  [T.createCollection]: 'Erstellen Sie eine Sammlung',

  //Users/Roles
  [T.userName]: 'Username',
  [T.rolesTitle]: 'Rollen',
  [T.role]: 'Rolle',
  [T.permissions]: 'Berechtigungen',
  [T.users]: 'Benutzer',
  [T.create]: 'Erstellen',
  [T.update]: 'Aktualisieren',
  [T.new]: 'Neu',
  [T.edit]: 'Bearbeiten',
  [T.delete]: 'Löschen',
  [T.tag]: 'Schlagwort',
  [T.upload]: 'hochladen',
  [T.createRole]: 'Rolle erstellen',
  [T.updateRole]: 'Rolle aktualisieren',
  [T.createUser]: 'Benutzer erstellen',
  [T.updateUser]: 'Benutzer aktualisieren',
  [T.searchRole]: 'Benutzerrolle suchen',
  [T.availableRole]: 'Verfügbare Benutzerrolle',
  [T.assignedRole]: 'Zugewiesene Benutzerrolle',
  [T.confirmDeleteRole]:
    'Sind Sie sicher, dass Sie diese Rolle löschen möchten?',
  [T.confirmDeleteUser]:
    'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
  [T.confirmDeleteMultipleUsers]:
    'Sind Sie sicher, dass Sie diese Benutzer löschen möchten?',
  [T.roleDeleteSuccess]: 'Rolle erfolgreich gelöscht!',
  [T.deleteRow]: 'Zeile löschen',
  [T.randomError]: 'Ein Fehler ist aufgetreten!',
  [T.noRecordFound]: 'Kein Eintrag gefunden!',
  [T.errorDeletingRole]: 'Fehler beim Löschen der Rolle.',
  [T.roleUpdateSuccess]: 'Rolle erfolgreich aktualisiert!',
  [T.roleUpdateError]: 'Fehler beim Aktualisieren der Rolle!',
  [T.roleCreateSuccess]: 'Rolle erfolgreich erstellt!',
  [T.roleCreateError]: 'Fehler beim Erstellen der Rolle!',
  [T.userInviteError]: 'Fehler beim Einladen von Benutzern.',
  [T.userInviteSuccess]:
    'Eine E-Mail-Einladung wurde an eingeladene Benutzer gesendet.',
  [T.userUpdateSuccess]: 'Benutzer erfolgreich aktualisiert!',
  [T.userUpdateFailed]: 'Fehler beim Aktualisieren des Benutzers.',
  [T.userDeleteSuccess]: 'Benutzer erfolgreich gelöscht!',
  [T.usersDeleteSuccess]: 'Benutzer erfolgreich gelöscht!',
  [T.userDeleteError]:
    'Benutzer konnte nicht gelöscht werden. Bitte versuchen Sie es erneut!',
  [T.duplicateUserError]: 'E-Mail ist bereits vergeben',
  [T.duplicateFolderError]: 'Datei/Ordner existiert bereits.',
  [T.duplicateCollectionError]: 'Die Sammlung existiert bereits.',
  [T.duplicateWorkspaceError]: 'Der Arbeitsbereich existiert bereits.',
  [T.renameSuccess]: 'Erfolgreich umbenannt!',
  [T.rename]: 'Umbenennen',
  [T.renameFailed]: 'Fehler beim Umbenennen!',
  [T.folderCreateSuccess]: 'Ordner erfolgreich erstellt!',
  [T.workspaceCreateSuccess]: 'Arbeitsbereich erfolgreich erstellt!',
  [T.collectionCreateSuccess]: 'Sammlung erfolgreich erstellt!',
  [T.folderCreateError]: 'Fehler beim Erstelen der Ressource!',
  [T.folderCreateSuccessAccesError]:
    'Ordner erfolgreich erstellt! Aktualisierung der Zugriffsberechtigungen fehlgeschlagen.',
  [T.folderUpdateSuccessAccessError]:
    'Ordner erfolgreich aktualisiert! Aktualisierung der Zugriffsberechtigungen fehlgeschlagen.',
  [T.enterRoleName]: 'Rollenname eingeben',
  [T.enterRoleDescription]: 'Rollenbeschreibung eingeben',
  [T.roleName]: 'Rollenname',
  [T.invalidRoleName]: 'Ungültiger Rollenname',
  [T.fullName]: 'Vollständiger Name',
  [T.enterFullName]: 'Vollständigen Namen eingeben',
  [T.enterEmailAddress]: 'E-Mail Adresse eingeben',
  [T.enterUserName]: 'Benutzernamen eingeben',
  [T.moveSelectedRight]: 'Ausgewählte nach rechts bewegen',
  [T.moveSelectedLeft]: 'Ausgewählte nach links bewegen',
  [T.applyTags]: 'Tags anwenden.',
  [T.dataLibrary]: 'Datenbibliothek',
  [T.language]: 'Sprache',
  [T.documentsStatus]: 'Status der hochgeladenen Dokumente',
  [T.statusFailedText]: 'Status: Fehlgeschlage',
  [T.statusUploadedText]: 'Status: Hochgeladen',
  [T.startDate]: 'Startdatum',
  [T.endDate]: 'Enddatum',
  [T.selectStatus]: 'Status auswählen',
  [T.document]: 'Dokument',
  [T.pages]: 'Seiten',
  [T.addARole]: 'Fügen Sie eine Rolle hinzu',
  [T.editRole]: 'Rolle bearbeiten',
  [T.organizationAdminSubtitle]:
    'Kann Arbeitsbereiche erstellen und zuweisen, Admins/Benutzer einladen, benutzerdefinierte Rollen hinzufügen.',
  [T.workspaceAdminSubtitle]:
    'Kann komplette Arbeitsbereiche verwalten, Benutzergruppen und reguläre Benutzer hinzufügen.',
  [T.regularUserSubtitle]:
    'Kann auf zugängliche Dokumente zugreifen, Inhalte hochladen und mit anderen Benutzern teilen.',

  // File Statuses
  [T.F_S_UP]: 'In Bearbeitung',
  [T.F_S_RE]: 'Wieder versuchend',
  [T.F_S_PR]: 'Verarbeitet',
  [T.F_S_US]: 'Nicht unterstützte',
  [T.F_S_LLR]: 'Lizenzlimit erreicht',
  [T.F_S_WF]: 'Warten auf Datei',
  [T.moveSelectedRight]: 'Ausgewählte nach rechts bewegen',
  [T.moveSelectedLeft]: 'Ausgewählte nach links bewegen',
  [T.workSpaceDocumentsDropError]:
    'Fügen Sie eine Sammlung hinzu, um die Dokumente darin hochzuladen.',
  // User Groups
  [T.failedToDeleteUserGroups]:
    'Benutzergruppen konnten nicht gelöscht werden.',
  [T.failedToDeleteUserGroup]: 'Benutzergruppe konnte nicht gelöscht werden.',
  [T.userGroupDeletedSuccessfully]: 'Benutzergruppe erfolgreich gelöscht.',
  [T.userGroupsDeletedSuccessfully]: 'Benutzergruppen erfolgreich gelöscht.',
  [T.userGroupCreatedSuccessfully]: 'Benutzergruppe erfolgreich erstellt.',
  [T.failedToCreateUserGroup]: 'Benutzergruppe konnte nicht erstellt werden.',
  [T.failedToUpdateUserGroup]:
    'Benutzergruppe konnte nicht aktualisiert werden.',
  [T.userGroupUpdatedSuccessfully]: 'Benutzergruppe erfolgreich aktualisiert.',
  [T.addUserGroup]: 'Fügen Sie eine Benutzergruppe hinzu',
  [T.editUserGroup]: 'Benutzergruppe bearbeiten',
  [T.searchUsers]: 'Benutzer suchen',
  [T.createdBy]: 'Erstellt von',
  [T.userGroupDeleteConfirmationMsg]:
    'Sind Sie sicher, dass Sie Benutzergruppen löschen?',

  // no_data_components
  [T.noDataNameSpaceMessage]:
    'Erstellen Sie einen Arbeitsbereich, um mit dem Hochladen von Daten zu beginnen.',

  [T.noDataWorkspaceMessage]:
    'Laden Sie einen Ordner hoch oder erstellen Sie eine Sammlung, um Daten hochzuladen.',
  [T.noDataCollectionMessage]: 'Dateien/Ordner hochladen',
  [T.noDataNoPermissionMessage]:
    'Fordern Sie den Administrator auf, Zugriff auf Dokumente zu erhalten.',
  [T.noData]: 'Keine Daten',
  [T.workSpaceInfoMessage]:
    'Neue Arbeitsbereiche können nur von Organisationsadministratoren erstellt werden.',
  [T.moveDialogInfo]:
    'Verschobene Inhalte behalten ihre ursprünglichen Quellenberechtigungen bei.',
  // validations
  [T.maxLength]:
    '{{name}} Der Rollenname darf nicht länger als {{length}} Zeichen sein',
  [T.required]: '{{name }} ist erforderlich',
  [T.tagHelperText]:
    'Drücken Sie die Eingabetaste, um ein neues Tag zu erstellen oder eines aus der Liste auszuwählen.',

  // Error Messages
  [T.namespaceError]: 'Namespace-Fehler ist aufgetreten.',
  [T.resourceNotFoundError]: 'Ressource nicht gefunden.',
  [T.noPermissionsAgainstResourceError]:
    'Keine Berechtigungen für die Ressource.',
  [T.insufficientPrivileges]:
    'Berechtigung zur Ausführung der Aktion nicht gefunden.',
  [T.appRoleTemplateNotFoundError]: 'Vorlagenrolle der App nicht gefunden.',
  [T.uploadTypeError]: 'Ungültiger Dateityp für den Upload bereitgestellt.',
  [T.transactionIdError]: 'Ungültige Transaktions-ID bereitgestellt.',
  [T.uploadToRootError]: 'Fehler beim Hochladen in das Stammverzeichnis.',
  [T.uploadToFoundError]:
    "Sie dürfen keine Dateien und Ordner im Ordner 'FOUND' hochladen.",
  [T.uploadToFileError]:
    'Dateien können nur in gültigen Ordnern hochgeladen werden.',
  [T.fileUploadToWorkspaceError]:
    'Dateien können auf der Workspace-Ebene nicht hochgeladen werden.',
  [T.noFilesAttachedError]:
    'Wählen Sie gültige Dateien aus, um mit dem Hochladen zu beginnen.',
  [T.fileStorageWriteError]: 'Fehler beim Schreiben in die Dateispeicherung.',
  [T.fileStorageReadError]: 'Fehler beim Lesen aus der Dateispeicherung.',
  [T.requestedResourceNotFile]: 'Angeforderte Ressource ist keine Datei.',
  [T.fileStorageDeleteError]: 'Fehler beim Löschen aus der Dateispeicherung.',
  [T.publicFolderDeleteError]:
    'Öffentlicher Ordner kann nicht gelöscht werden.',
  [T.internalServerError]: 'Etwas ist schiefgegangen.',
  [T.invalidArgsError]: 'Ungültige Argumente bereitgestellt.',
  [T.folderNotAllowedHere]: 'Sie dürfen hier keinen Ordner erstellen.',
  [T.rolesInsufficientViewPrivileges]:
    'Sie haben keine Berechtigung, Rollen anzuzeigen.',
  [T.userGroupsInsufficientPrivileges]:
    'Sie haben keine Berechtigung, Benutzergruppen anzuzeigen.',
  [T.waCreateUserRestriction]:
    'Workspace-Administratoren können nur normale Benutzer erstellen.',
  [T.readOnlyResourceUpdate]:
    'Sie dürfen die schreibgeschützte Ressource nicht aktualisieren.',
  [T.readOnlyResourceDelete]:
    'Sie dürfen die schreibgeschützte Ressource nicht löschen.',
  [T.rolesUniqueVoilation]: 'Die Rolle mit diesem Namen existiert bereits.',
  [T.addPermissionsAgainstResourceError]:
    'Fehler beim Hinzufügen von Berechtigungen für die Ressource.',
  [T.permissionsUpdatingErrorForRole]:
    'Fehler beim Aktualisieren von Berechtigungen für die Ressource.',
  [T.insufficientCreatePrivileges]:
    'Sie haben keine Berechtigung, eine Ressource zu erstellen.',
  [T.insufficientUpdatePrivileges]:
    'Sie haben keine Berechtigung, die Ressource zu aktualisieren.',
  [T.insufficientDeletePrivileges]:
    'Sie haben keine Berechtigung, die Ressource zu löschen.',
  [T.insufficientDeleteOneOrMorePrivileges]:
    'Sie haben keine Berechtigung, einige Ressourcen zu löschen.',
  [T.usersUniqueVoilation]: 'Benutzer existiert bereits.',
  [T.userGroupUniqueVoilation]:
    'Benutzergruppe mit demselben Namen existiert bereits.',
  [T.selfAssignmentOnResourceRestriction]:
    'Sie können sich selbst keine Ressource zuweisen.',
  [T.ruAssignmentAsControllerRestriction]:
    'Normale Benutzer können nicht als Kontrolleur zugewiesen werden.',
  [T.oaRemovalFromResourceDenied]:
    'Organisationsadministrator kann nicht von der Ressource entfernt werden.',
  [T.insufficientAssignmentPrivileges]:
    'Sie haben nicht genügend Rechte, um diese Aktion auszuführen.',
  [T.peerAccountAssignmentRestriction]:
    'Sie dürfen keine gleichen oder höheren Konten entfernen.',
  [T.messageOnEmptyFolderOrUnavailableFile]:
    'Der Chat kann nicht auf leeren oder nicht verfügbaren Ressourcen gestartet werden.',
  [T.noSharedFilesExist]:
    'Bitte laden Sie Dokumente in die Arbeitsbereiche hoch oder bitten Sie den Administrator um Zugang, um mit der Befragung zu beginnen.',
  [T.itemDetailsNotFoundError]:
    'Details für diese Ressource konnten nicht gefunden werden.',

  [T.operationNotPermitted]:
    'Berechtigung zur Ausführung der Aktion nicht gefunden.',
  [T.uniqueFolderNameError]: 'Ordner existiert bereits.',
  [T.noResourcesShared]:
    'Nur autorisierte Dokumente sind erlaubt. Bitte laden Sie zuerst ein Dokument hoch.',
  // overLimit messages
  [T.chatCreditLimitExceededOA]:
    'Monatliches Limit für AI-Credits erreicht. Für sofortige Hilfe wenden Sie sich an den Kundensupport unter „support@empowergpt.ai“.',
  [T.chatCreditLimitExceeded]:
    'Monatliches Limit für AI-Credits erreicht. Bitte wenden Sie sich an Ihren Administrator.',
  [T.fileCreditLimitExceededOA]:
    'Dokumentenverarbeitung angehalten, monatliches Limit für AI-Credits erreicht. Für sofortige Hilfe wenden Sie sich an den Kundensupport unter „support@empowergpt.ai“.',
  [T.fileCreditLimitExceeded]: `Dokumentenverarbeitung angehalten, monatliches Limit für AI-Credits erreicht. Bitte wenden Sie sich an Ihren Administrator.`,
  [T.fileWordLimitExceededOA]:
    'Die Dokumentverarbeitung wurde angehalten, die Wortbeschränkung für verarbeitete gespeicherte Dokumente wurde erreicht. Bitte löschen Sie nicht mehr benötigte Dokumente, um Speicherplatz freizugeben. Für weitere Unterstützung wenden Sie sich an den Kundensupport unter „support@empowergpt.ai“.',
  [T.fileWordLimitExceeded]: `
  Die Dokumentenverarbeitung wurde angehalten, die Wortbeschränkung für verarbeitete gespeicherte Dokumente wurde erreicht. Bitte löschen Sie nicht mehr benötigte Dokumente, um Speicherplatz freizugeben.`,
  [T.creditLimitExceeded90Percent]:
    'Über 90 % der AI Credits wurden verbraucht.',
  [T.wordLimitExceeded90Percent]:
    'Über 90 % des zugewiesenen Wortlimits für verarbeitete gespeicherte Dokumente wurden erreicht.',
  [T.wordLimitReached100Percent]:
    'Das zugewiesene Wortlimit für verarbeitete gespeicherte Dokumente wurde erreicht. Bitte löschen Sie nicht mehr benötigte Dokumente, um Speicherplatz freizugeben. Für weitere Unterstützung wenden Sie sich an den Kundensupport unter „support@empowergpt.ai“.',

  // Usage
  [T.processedDocuments]: 'Verarbeitete Dokumente',
  [T.overallUsage]: 'Gesamtnutzung',
  [T.aiUsage]: ' AI-Nutzung',
  [T.monthlyUsage]: 'Monatliche Nutzung',
  [T.dailyUsage]: 'Tägliche Nutzung',
  [T.processedDocumentsInfo]:
    'Geschätzte Anzahl von Wörtern gegenüber gespeicherten verarbeiteten Dokumenten in der Arbeitsbereiche.',
  [T.tokenUsageInfo]:
    '{{consumed}} {{unit}} verarbeiteter Dokumente in der Arbeitsbereiche von dem {{total}} {{unit}} limit.',
  [T.aiUsageInfo]: 'AI-Nutzung für Chat und Dokumentenverarbeitung.',
  [T.creditUsageInfo]:
    '{{consumed}} {{unit}} verwendet von dem {{total}} {{unit}} limit.',
  [T.credits]: 'Credits',
  [T.consumed]: 'Verbraucht',
  [T.date]: 'Datum',
  [T.noCreditsUsedThisMonth]: 'Diesen Monat wurden keine Guthaben verwendet.',
  [T.filesPermission]: 'Dateien sind hier nicht erlaubt',
  [T.unauthorizedFilesError]:
    'Sie haben keine Berechtigung, Dateien/Ordner hochzuladen',

  // users invitations
  [T.inviteUsers]: 'Benutzer einladen',
  [T.emailAddress]: 'E-Mail-Addresse',
  [T.userGroup]: 'Benutzergruppe',
  [T.active]: 'Aktiv',
  [T.invited]: 'Eingeladen',
  [T.invitationExpired]: 'Einladung abgelaufen',
  [T.inactive]: 'Inaktiv',
  [T.deActivate]: 'Deaktivieren',
  [T.activate]: 'Aktivieren',
  [T.resendInvite]: 'Erneut senden',
  [T.inviteSentSuccessfully]: 'Einladung erfolgreich gesendet!',
  [T.inviteSentFailed]: 'Fehler beim Senden der Einladung!',
  [T.userActivated]: 'Benutzer erfolgreich aktiviert!',
  [T.userDeactivated]: 'Benutzer erfolgreich deaktiviert!',
  [T.userActivationFailed]: 'Fehler beim Aktivieren des Benutzers!',
  [T.userDeactivationFailed]: 'Fehler beim Deaktivieren des Benutzers!',
  [T.adUserNotFoundError]: 'Benutzer nicht in AD gefunden.',
  [T.invalidEmail]: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',

  // SharePoint
  [T.sharePointAuthError]:
    'Du könntest möglicherweise keine Verbindung zu SharePoint herstellen aufgrund eines Konnektivitätsproblems.',
  [T.sharepoint]: 'Importieren von SharePoint',
  [T.sharepointModalTitle]: 'Ordner/Bibliothek auswählen',
  [T.sharepointModalSubtitle]:
    'Navigieren Sie zur gewünschten Site und wählen Sie den Zielordner / die Zielbibliothek für den Import aus',
  [T.availableSites]: 'Verfügbare Sites',
  [T.selectSiteMsg]:
    'Wählen Sie eine Site aus, um verfügbare Site-Inhalte anzuzeigen',
  [T.siteContent]: 'Site-Inhalt',
  [T.showSiteContent]: 'Site-Inhalt anzeigen',
  [T.select]: 'Wählen',
  [T.resourceImportedSuccessfully]: 'Ressource erfolgreich importiert.',
  [T.rootSite]: 'Hauptseiten',
  [T.sharePointSuccessModalTitle]: 'Ordner / Bibliothek erfolgreich verknüpft',
  [T.sharePointSuccessModalLine1]:
    'Alles erledigt! Der ausgewählte Ordner / die Bibliothek sowie ihre Inhalte sind für Sie als Sammlung zugänglich, sobald die Datensynchronisierung abgeschlossen ist.',
  [T.sharePointSuccessModalLine2]:
    'Die Sichtbarkeit der Sammlung basiert ausschließlich auf den Berechtigungen in SharePoint.',
  [T.noSubSiteAvailable]: 'Kein Unterwebsite verfügbar.',
  [T.noFolderFileAvailable]: 'Kein Ordner / Datei verfügbar.',
  [T.openInSharePoint]: 'In SharePoint öffnen',
  [T.Navigate]: 'Navigieren',
  [T.redirectUri]: 'Umleitungs URI',
  [T.confirmationStep1]:
    'Die oben genannten Schritte wurden auf die registrierte Anwendung in Microsoft Entra ID durchgeführt.',
  [T.signInWithMicrosoft]: 'Mit Microsoft anmelden (SSO)',
  [T.allowInvitingExternalUsers]: 'Einladen externer Benutzer erlauben',
  [T.cloudDriveIntegration]: 'Integration von Cloud-Laufwerken',
  [T.syncFrequency]: 'Synchronisationsfrequenz (Minuten)',
  [T.contentImportFromSharepoint]: 'Inhalt aus SharePoint importieren',
  [T.letsStart]: 'Lass uns anfangen',
  [T.next]: 'Nächster',
  [T.done]: 'Erledigt',
  [T.welcomeScreenHeading1]: 'Schritte für SSO',
  [T.welcomeScreenHeading2]: 'Konfiguration',
  [T.registrationStep]: 'Registrieren Sie "EmpowerGPT" in Microsoft Entra ID',
  [T.azureDetailsStep]:
    'Geben Sie Details gegen die registrierte Anwendung auf Microsoft Entra ID ein',
  [T.configurePermissions]:
    'Geben Sie die erforderlichen Berechtigungen gegen verwendete APIs an die registrierte App in Microsoft Entra ID',
  [T.registrationPoint1]: 'In einem anderen Tab anmelden',
  [T.azurePortal]: 'Azure Portal',
  [T.roleAzurePortal]: 'als admin.',
  [T.registrationPoint2]:
    'Navigieren Sie zu "Microsoft Entra ID" (Azure Active Directory) > "App registrations" als admin.',
  [T.registrationPoint3]: 'Klicken Sie auf "+ Neue Registrierung".',
  [T.registrationPoint4]:
    'Geben Sie "EmpowerGPT" als Namen der Anwendung ein und wählen Sie die erforderlichen unterstützten Kontotypen aus. (Empfohlen: "Accounts in this organizational directory only").',
  [T.registrationPoint5]:
    'Wählen Sie unter dem Abschnitt "Redirect URI" im Anmeldeformular der Anwendung "Web" als Typ aus und fügen Sie die folgende Redirect URI in das entsprechende Feld ein.',
  [T.confirmationStep1]:
    'Die oben genannten Schritte wurden auf die registrierte Anwendung in Microsoft Entra ID durchgeführt.',
  [T.redirectUriRequired]: 'Redirect URI ist erforderlich',
  [T.azureDetailsPoint1]:
    'Sobald die Anwendung registriert ist, werden Sie zur "Overview" der registrierten Anwendung weitergeleitet.',
  [T.azureDetailsPoint2]:
    'Kopieren Sie die "Application (client) ID" und "Directory (tenant) ID" von der "Overview" Seite und geben Sie sie in die jeweiligen Felder unten ein.',
  [T.azureDetailsPoint3]:
    'Navigieren Sie zu "Certificates and secrets" unter dem Abschnitt "Manage" auf der registrierten Anwendung in Microsoft Entra ID.',
  [T.azureDetailsPoint4]:
    'Wählen Sie die Registerkarte "Client secrets" und klicken Sie auf die Schaltfläche "New client secret", um ein neues Client Secret zu erstellen. Geben Sie "EmpowerGPT client secret" in der Beschreibung ein, setzen Sie das Ablaufdatum auf 24 Monate (empfohlen) und klicken Sie auf "add".',
  [T.azureDetailsPoint5]:
    'Kopieren Sie den geheimen "Wert" und fügen Sie ihn in das Feld unten ein. Client Secret-Werte können nicht angezeigt werden. außer unmittelbar nach der Erstellung. Stellen Sie sicher, dass Sie den sicheren Wert beim Erstellen speichern, bevor Sie die Seite verlassen.',
  [T.clientIdRequired]: 'Client Id ist erforderlich',
  [T.labelClientId]: 'Anwendungs (Client) Id:',
  [T.tenantIdRequired]: 'Tenant Id ist erforderlich',
  [T.labelTenantId]: 'Verzeichnis (Mandant) Id:',
  [T.secretValueRequired]: 'Secret Value ist erforderlich',
  [T.labelSecret]: 'Client Secret Value:',
  [T.enter]: 'Geben Sie ein...',
  [T.permissionsStep1]:
    'Navigieren Sie zu "API permissions" unter dem Abschnitt "Manage" auf der registrierten Anwendung in Microsoft Entra ID.',
  [T.permissionsStep2]:
    'Klicken Sie auf die Schaltfläche "+ Add a permission" und wählen Sie "Microsoft Graph" unter Microsoft APIs.',
  [T.permissionsStep3]:
    'Wählen Sie "Application permissions", um die folgenden Berechtigungen zu erteilen, und bestätigen Sie dies, indem Sie auf die Schaltfläche "add permission" unten klicken.',
  [T.permissionsStep4]: 'User.Read.All unter User.',
  [T.permissionsStep5]: 'Group.Read.All unter Group.',
  [T.permissionsStep6]: 'GroupMember.Read.All unter GroupMember.',
  [T.permissionsStep7]:
    'Wiederholen Sie Schritt 2 und wählen Sie "Delegated permissions" aus, um die Berechtigung "profile" zu erteilen. Bestätigen Sie durch Klicken auf die Schaltfläche "add permission" unten.',
  [T.permissionsStep8]:
    'Die oben genannten Schritte wurden auf die registrierte Anwendung in Microsoft Entra ID durchgeführt.',
  [T.successDialogTitle]: 'SSO erfolgreich konfiguriert',
  [T.successDialogDescription]:
    'SS0 wurde erfolgreich gegen Benutzer in dem verknüpften Verzeichnis konfiguriert.',
  [T.clickHere]: 'Klicken Sie hier',
  [T.toLogout]: ' um sich abzumelden und die Konfiguration zu testen.',
  [T.collectionSharepointPlaceholder]: 'Importieren aus Sharepoint',
  [T.collectionSharepointDescription]:
    'Importieren Sie Inhalte von Sharepoint nach EmpowerGPT',

  // One Drive
  [T.oneDriveMyFiles]: 'Meine Akten',
  [T.oneDriveShared]: 'Mit Ihnen geteilt',
  [T.oneDrive]: 'Von einem Laufwerk importieren',
  [T.oneDriveModalTitle]: 'Wählen Sie einen Ordner/eine Bibliothek aus',
  [T.oneDriveModalSubtitle]:
    'Navigieren Sie in Ihrem OneDrive und wählen Sie den Zielinhalt für den Import aus',
  [T.oneDriveContent]: 'Inhalt',
  [T.oneDriveSuccessModalTitle]: 'Ordner/Bibliothek erfolgreich verknüpft',
  [T.oneDriveSuccessModalLine1]:
    'Alles bereit! Sobald die Datensynchronisierung abgeschlossen ist, stehen Ihnen der ausgewählte Ordner/die ausgewählte Bibliothek und ihr Inhalt als Sammlung zur Verfügung.',
  [T.oneDriveSuccessModalLine2]:
    'Die Sichtbarkeit der Sammlung basiert ausschließlich auf ihren Berechtigungen bei One Drive.',
  [T.openInOneDrive]: 'In One Drive öffnen',
  [T.contentImportFromOneDrive]: 'Inhaltsimport von One Drive',
  [T.oneDriveResourceProviderWelcomeTitle]:
    'Schritte zum Importieren aus der OneDrive-Konfiguration',
  [T.oneDriveConfigurationSuccessTitle]: 'Import aus OneDrive konfiguriert',
  [T.oneDriveConfigurationSuccessDescription1]:
    'Inhalte können jetzt als Sammlungen aus OneDrive in EmpowerGPT importiert werden.',
  [T.oneDriveConfigurationSuccessDescription2]:
    'Die Sichtbarkeit importierter Inhalte basiert auf ihren Berechtigungen bei OneDrive.',
  [T.collectionOneDrivePlaceholder]: 'Importieren aus OneDrive',
  [T.collectionOneDriveDescription]:
    'Importieren Sie Inhalte von OneDrive nach EmpowerGPT',

  // Common External Resource provider
  [T.collectionExternalResProvPlaceholder]: 'Import aus Sharepoint / OneDrive',
  [T.collectionExternalResProvDescription]:
    'Importieren Sie Inhalte von Sharepoint oder OneDrive in EmpowerGPT',
  [T.externalResourceDisclaimer]:
    "Die Sichtbarkeit des Inhalts basiert ausschließlich auf seinen Berechtigungen an der Quelle, außer in 'Öffentlich' oder 'Mein Arbeitsbereich'.",

  // resource Provider config
  [T.resourceProviderWelcomeTitle]:
    'Schritte für die Konfiguration des Imports aus SharePoint',
  [T.resourceProviderTitle1]:
    'Laden Sie das generierte Zertifikat in der registrierten Anwendung "EmpowerGPT" auf Microsoft Entra ID hoch',
  [T.resourceProviderStep1Instruction1]:
    'Fügen Sie die Basis-URL für das SharePoint Ihrer Organisation unten ein. (Die Basis-URL für SharePoint Online wird basierend auf Ihrer Eingabe bei der Einrichtung Ihres Microsoft 365-Abonnements bereitgestellt. Wenn Ihr Organisationsname beispielsweise Contoso ist, lautet die Root-Site für SharePoint Online https://contoso.sharepoint.com.).',
  [T.resourceProviderStep1Instruction2]:
    'Klicken Sie unten auf die Schaltfläche, um das erforderliche Zertifikat herunterzuladen.',
  [T.resourceProviderStep1Instruction3]:
    'Melden Sie sich in einem anderen Tab als Administrator im Azure-Portal an.',

  [T.resourceProviderStep1Instruction4]:
    'Navigieren Sie zu "Microsoft Entra ID" (Azure Active Directory) > "App registrations" > "All applications" > "EmpowerGPT".',
  [T.resourceProviderStep1Instruction5]:
    'Wählen Sie die Anwendung aus und navigieren Sie zum Abschnitt "Certificates and secrets" unter "Manage".',
  [T.resourceProviderStep1Instruction6]:
    'Wählen Sie den Tab "Certificates" aus und klicken Sie auf "Upload certificate". Laden Sie das oben generierte Zertifikat, das auf Ihrem Computer heruntergeladen wurde, hoch.',
  [T.resourceProviderStep1Instruction7]:
    'Geben Sie eine passende Beschreibung ein und klicken Sie auf die Schaltfläche "Add", um das Zertifikat hinzuzufügen.',

  [T.resourceProviderTitle2]:
    'Geben Sie die erforderlichen Berechtigungen für die verwendeten APIs an die registrierte App in Microsoft Entra ID weiter',
  [T.resourceProviderStep2Instruction1]:
    'Navigieren Sie zu "API permissions" unter dem Abschnitt "Manage" in der registrierten Anwendung in Microsoft Entra ID.',
  [T.resourceProviderStep2Instruction2]:
    'Klicken Sie auf die Schaltfläche "+ Add a permission" und wählen Sie "Microsoft Graph" unter Microsoft APIs aus.',
  [T.resourceProviderStep2Instruction3]:
    'Wählen Sie "Application permissions" aus, um die folgenden Berechtigungen zu erteilen, und bestätigen Sie durch Klicken auf die Schaltfläche "add permission" unten.',
  [T.resourceProviderStep2Instruction5]: 'Sites.Read.All.',
  [T.resourceProviderStep2Instruction6]: 'Files.Read.All.',
  [T.resourceProviderStep2Instruction7]:
    'Wiederholen Sie Schritt 2 und wählen Sie "Delegated permissions" aus, um die folgenden Berechtigungen zu erteilen. Bestätigen Sie durch Klicken auf die Schaltfläche "add permission" unten.',
  [T.resourceProviderStep2Instruction8]:
    'Klicken Sie auf die Schaltfläche "+ Add a permission" und wählen Sie "SharePoint" unter Microsoft APIs aus, um die folgenden "Application permission" zu erteilen. Klicken Sie auf "add permission", um zu bestätigen.',
  [T.sharepointConfigurationSuccessTitle]: 'Import aus SharePoint konfiguriert',
  [T.sharepointConfigurationSuccessDescription1]:
    'Inhalte können jetzt aus SharePoint-Sites in EmpowerGPT als Sammlungen importiert werden.',
  [T.sharepointConfigurationSuccessDescription2]:
    'Die Sichtbarkeit der importierten Inhalte basiert auf den Berechtigungen in SharePoint.',
  [T.downloadCertificate]: 'Zertifikat herunterladen',
  [T.invalidSharePointUrl]: 'Ungültige SharePoint URL bereitgestellt.',
  [T.identityProviderEnabledSuccessfully]:
    'Microsoft (SSO) erfolgreich aktiviert.',
  [T.resourceProviderSharePointEnabledSuccessfully]:
    'SharePoint erfolgreich aktiviert.',
  [T.resourceProviderSharePointDisabledSuccessfully]:
    'SharePoint erfolgreich deaktiviert.',
  [T.resourceProviderOneDriveEnabledSuccessfully]:
    'OneDrive erfolgreich aktiviert.',
  [T.resourceProviderOneDriveDisabledSuccessfully]:
    'OneDrive erfolgreich deaktiviert.',
  [T.identityProviderDisabledSuccessfully]:
    'Microsoft (SSO) erfolgreich deaktiviert.',
  [T.identityProviderUpdateFailed]: 'Fehler beim Aktivieren von SharePoint.',
  [T.identityProviderUpdateFailed]:
    'Fehler beim Aktivieren von Microsoft (SSO).',
  [T.identityProviderCreationFailed]:
    'Fehler beim Aktivieren von Microsoft (SSO).',
  [T.resourceProviderSharePointCreationFailed]:
    'Fehler beim Aktivieren von SharePoint.',
  [T.resourceProviderOneDriveCreationFailed]:
    'Fehler beim Aktivieren von OneDrive.',
  [T.disabledToolTipMicrosoftSSO]:
    'Konfigurieren Sie Microsoft SSO, um zu aktivieren.',
  [T.requiredCheckbox]: 'Dieses Feld ist erforderlich.',
  [T.adminConsent]:
    'Stellen Sie sicher, dass die Administratorzustimmung für Berechtigungen erteilt wird, die dies erfordern, was durch ein "Yes" in der Spalte "Admin consent required" der Tabelle "Configured permissions" angezeigt wird.',

  //Internal Analytics
  [T.analytics]: ' Analytik',
  [T.questions]: 'Fragen',
  [T.dislikes]: 'Antipathien',

  // User Favourites
  [T.favourites]: 'Favoriten',
  [T.markFavourite]: 'Als favorit markieren',
  [T.unmarkFavourite]: 'Markierung als favorit aufheben',
  [T.markedFavourite]: 'Als favorit markiert',
  [T.unmarkedFavourite]: 'Nicht als favorit markiert',
  [T.markFavouriteError]: 'Fehler beim markieren als favorit',
  [T.unmarkFavouriteError]: 'Fehler beim aufheben der markierung als favorit',
  [T.favouriteLimitReached]: 'Das Limit der benutzerfavoriten wurde erreicht',

  // User Onboarding
  [T.welcomeText]: `Willkommen bei`,
  [T.welcomeText1]: `EmpowerGPT ist eine KI-Plattform, die für Unternehmen gemacht ist. Chatten Sie sicher mit hochmodernen LLMs, die Ihr Unternehmens- und Weltwissen einbeziehen.`,
  [T.welcomeText2]: `Erweitern Sie die Wissensbasis Ihres Unternehmens durch Hochladen oder Importieren von Daten aus integrierten Anwendungen.`,
  //Chat
  [T.gotIt]: 'Verstanden',
  [T.onNext]: 'Klicken Sie auf Weiter, um fortzufahren.',
  [T.chatStep1Title]: 'Plaudern: Wählen Sie den gewünschten Wissensbereich aus',
  [T.chatStep1Description]:
    'Wählen Sie, wie Sie EmpowerGPT antworten lassen wollen. Bleiben Sie beim Unternehmenswissen, um Antworten auf die zugänglichen Unternehmensdokumente zu erhalten. Oder wechseln Sie zu Weltwissen, um Unterstützung bei der Erstellung von Inhalten, Codierung, Recherche und Zugang zu Weltwissen zu erhalten.',
  [T.chatStep2Title]: 'Plaudern: Geben Sie Ihre Anfrage ein.',
  [T.chatStep2Description]: `Verwenden Sie das Eingabefeld unten, um Ihre Fragen einzugeben, und klicken Sie auf die Schaltfläche "Senden" auf der rechten Seite. Der Kontext zu früheren Anfragen und Antworten ist innerhalb eines Chats begrenzt`,
  [T.chatStep3Title]: 'Plaudern: Überprüfen Sie die Fakten.',
  [T.chatStep3Description]: `Chat-Antworten in Unternehmenswissen sind am Ende mit Quellenangaben versehen. Es wird empfohlen, die Authentizität der Antwort durch Einsicht in die Referenzen zu überprüfen.`,
  [T.chatQuery]:
    'Können Sie eine E-Mail schreiben, um dem Team zum Erreichen der Quartalsziele zu gratulieren?',
  [T.today]: 'Heute',
  [T.thisWeek]: 'Diese Woche',
  [T.thisMonth]: 'Diesen Monat und älter',
  [T.chatHistory]: 'Chat-Verlauf',

  //Data Library
  [T.documentStep1Title]: 'Hochladen: Navigieren Sie zur Arbeitsbereiche.',
  [T.documentStep1Description]:
    'Data Library enthält Arbeitsbereiche, die für Sie freigegeben wurden. Arbeitsbereiche kategorisieren Dokumente innerhalb einer Organisation.',
  [T.documentStep2Title]:
    'Hochladen: Wählen Sie den gewünschten Arbeitsbereich.',
  [T.documentStep2Description]:
    'Die Aktionen, die in einem Arbeitsbereich verfügbar sind, hängen von der Rolle ab, die Sie dort zugewiesen bekommen haben. "Mein Arbeitsbereich" ist ein persönlicher Arbeitsbereich, dem Sie Dokumente hinzufügen und mit dem Sie chatten können.',
  [T.documentStep3Title]: `Hochladen: Erstellen Sie eine Sammlung.`,
  [T.documentStep3Description]:
    'Sammlungen dienen der weiteren Klassifizierung von Dokumenten in jedem Arbeitsbereich. Erstellen Sie Ihre Sammlung im Arbeitsbereich "Mein", um darin Dokumente hochzuladen.',
  [T.documentStep4Title]: 'Hochladen: Dokumente zum Hochladen auswählen',
  [T.documentStep4Description]:
    'EmpowerGPT unterstützt eine Vielzahl von Dokumentenformaten, die der Wissensdatenbank hinzugefügt werden können, darunter PDFs (auch gescannte Dokumente), Word-Dokumente, Powerpoint-Folien und Excel-Dateien.',
  [T.documentStep5Title]: 'Hochladen: Verständnis des Dokumentenstatus',
  [T.documentStep5Description]:
    'Einmal hochgeladene Dokumente werden für die KI-Verarbeitung in eine Warteschlange gestellt. Sobald sie als „Bearbeitet“ markiert sind, können Benutzer, die Zugang zu diesen Dokumenten haben, mit ihnen chatten.',

  // Invite User
  [T.inviteUserStep1Title]: 'Einladen: Öffnen Sie das Menü',
  [T.inviteUserStep1Description]:
    'Die Menüschaltflächen enthalten Links zu Seiten, die für die Einrichtung und Verwaltung der Anwendung und Ihres Kontos erforderlich sind.',
  [T.inviteUserStep2Title]: 'Einladen: Navigieren Sie zur Benutzerseite',
  [T.inviteUserStep2Description]:
    'Die Seite Benutzer enthält eine Liste aller Personen, die sich derzeit auf der empowerGPT-Plattform befinden. Admins können von dieser Seite aus Benutzer einladen.',
  [T.inviteUserStep3Title]:
    'Einladen: Geben Sie die E-Mail des Eingeladenen ein.',
  [T.inviteUserStep3Description]:
    'Geben Sie die E-Mail-Adressen der Eingeladenen ein, wählen Sie den Kontotyp und ggf. die Benutzergruppe aus, bevor Sie die Einladung versenden.',

  // Chat models comments and descriptions
  [T.mistralLargeComment]: 'Alternative',
  [T.mistralLargeDescription]: 'Gehostet auf Microsoft Azure in der EU',
  [T.llama370BInstructComment]: 'Offene Quelle',
  [T.llama370BInstructDescription]: 'Gehostet auf Microsoft Azure in der EU',
  [T.llama38BInstructComment]: 'Offene Quelle',
  [T.llama38BInstructDescription]: 'Gehostet auf Microsoft Azure in der EU',
  [T.gpt4TurboComment]: 'Demnächst verfügbar',
  [T.gpt4TurboDescription]: 'Gehostet auf Microsoft Azure in der EU',
  [T.gpt432kComment]: 'Beste',
  [T.gpt432kDescription]: 'Gehostet auf Microsoft Azure in der EU',
  [T.gpt4oComment]: 'Schnell und genau',
  [T.gpt4oDescription]: 'Gehostet auf Microsoft Azure in der EU',
  [T.cohereComment]: 'Optimiert für Unternehmen',
  [T.cohereDescription]: 'Gehostet auf Microsoft Azure in der EU',
  [T.gpt35Turbo16kComment]: 'Schnell',
  [T.gpt35Turbo16kDescription]: 'Gehostet auf Microsoft Azure in der EU',

  // Prompts
  [T.promptTitle1]: 'Kürzer machen',
  [T.promptText1]:
    'Fassen Sie den folgenden Text auf [einfügen_Wort_zahl] Wörter zusammen, wobei alle wichtigen Informationen erhalten bleiben müssen: [einfügen_text_hier].',
  [T.promptTitle2]: 'Länger machen',
  [T.promptText2]:
    'Erweitern Sie den folgenden Text auf [einfügen_Wort_zahl] Wörter und achten Sie darauf, dass keine irrelevanten Details hinzugefügt werden: [einfügen_text_hier].',
  [T.promptTitle3]: 'Ton ändern',
  [T.promptText3]:
    'Schreiben Sie den folgenden Text in einem [spezifizierten_Ton] um und achten Sie darauf, dass die Botschaft klar und wirksam bleibt: [einfügen_text_hier].',
  [T.promptTitle4]: 'Grammatik und Rechtschreibung korrigieren',
  [T.promptText4]:
    'Korrigieren Sie die Grammatik und Rechtschreibung im folgenden Text und heben Sie alle Änderungen hervor, indem Sie die korrigierten Wörter fett markieren: [einfügen_text_hier].',
  [T.promptTitle5]: 'Übersetzen Sie',
  [T.promptText5]:
    'Übersetzen Sie den folgenden Text in die [angegebene_Sprache] und stellen Sie dabei sicher, dass alle benannten Einheiten, Abkürzungen und Akronyme korrekt erhalten bleiben: [einfügen_text_hier].',
  [T.promptTitle6]: 'Zusammenfassend',
  [T.promptText6]:
    'Fassen Sie den folgenden Text auf [Wortanzahl] Wörter zusammen und achten Sie darauf, dass alle wichtigen Informationen erhalten bleiben und die Gesamtaussage unverändert bleibt: [einfügen_text_hier].',
  [T.promptTitle7]: 'Feedback geben',
  [T.promptText7]:
    'Geben Sie ein Feedback zu dem folgenden Text und konzentrieren Sie sich dabei auf die angegebenen Bereiche: [Technik, Tonfall, Grammatik, Format]. Achten Sie darauf, dass Ihr Feedback konstruktiv ist und zur Verbesserung des Textes beiträgt: [einfügen_text_hier].',
  [T.promptTitle8]: 'Brainstorming von Ideen',
  [T.promptText8]:
    'Machen Sie ein Brainstorming mit [Anzahl_der_Ideen] kreativen und praktischen Ideen für die folgende Aufgabe: [Aufgabe+optionale_Beispiele]',
  [T.promptTitle9]: 'Schreiben Sie eine E-Mail',
  [T.promptText9]:
    'Schreiben Sie eine E-Mail auf der Grundlage der folgenden Nachricht: [Nachricht]. Stellen Sie sicher, dass der Ton [specified_tone] und die Länge der E-Mail [specified_length] ist.',
  [T.promptTitle10]: 'Schreiben Sie einen Beitrag für soziale Medien',
  [T.promptText10]:
    'Schreiben Sie einen Social-Media-Beitrag für [spezifizierte_Plattform] in [spezifizierter_Ton], der sich an [ziel_Zielgruppe] richtet. Fügen Sie ansprechende und für die Plattform geeignete Inhalte ein.',

  // Mini Experts - Doc Analyst
  [T.miniExperts]: 'Mini-Experten',
  [T.docAnalyst]: 'Dokumentenanalyst',
  [T.docAnalystDescription]: `Doc Analyst ist ein Gen AI Mini-Experte, der Unternehmensdaten basierend auf maßgeschneiderten Fragen analysieren kann. Die wichtigsten Funktionen von Doc Analyst sind unten aufgeführt:`,
  [T.docClassifierDescription]:
    'Der Dokumentklassifizierer ist ein Gen AI Mini-Experte, der entwickelt wurde, um Dokumente automatisch basierend auf ihrem Inhalt zu kategorisieren und zu kennzeichnen.',
  [T.docSummarizerDescription]:
    'Der Zusammenfassungs-Generator fasst automatisch umfangreiche Dokumente in prägnante Zusammenfassungen zusammen und erfasst dabei die wichtigsten Punkte und wesentlichen Informationen.',
  [T.analysis]: 'Analyse',
  [T.documentClassification]: 'Dokumentenklassifikation',
  [T.comingSoon]: 'Demnächst verfügbar',
  [T.summaryGenerator]: 'Zusammenfassungs-Generator',
  [T.confirmationDialog]: 'Bestätigungsdialog',
  [T.dataCollections]: 'Datensammlungen',
  [T.initiate]: 'Initiieren',
  [T.newAnalysis]: 'Neue Analyse',
  [T.initiateAnalysis]: 'Analyse Initiieren',
  [T.dataset]: 'Datensatz',
  [T.questionnaire]: 'Fragebogen',
  [T.runAnalysis]: 'Analyse Durchführen',
  [T.runAnalysisPlaceholder]:
    'Um die Analyse zu starten, geben Sie den Namen der Analyse ein. \nWählen Sie Datensatz und Fragebogen und klicken Sie dann auf “',
  [T.importFromApp]: 'Aus der App importieren',
  [T.regenerateAnalysis]: 'Analyse Regenerieren',
  [T.saveAnalysis]: 'Analyse Speichern',
  [T.numberOfFiles]: 'Anzahl der Dateien',
  [T.numberOfQuestions]: 'Fragenanzahl',
  [T.clone]: 'Klonen',
  [T.filesImportSuccess]: 'Dateien/Ordner erfolgreich importiert',
  [T.filesImportFailure]: 'Dateien/Ordner-Import fehlgeschlagen',
  [T.import]: 'Importieren',
  [T.analysisTitle]: 'Analyse Titel',
  [T.datasetTitle]: 'Datensatz Titel',
  [T.analysisDeleteConfirmation]:
    'Sind Sie sicher, dass Sie die Analyse löschen wollen?',
  [T.analysisDeleteSuccess]: 'Analyse erfolgreich gelöscht!',
  [T.analysisDeleteFailed]:
    'Das Löschen der Analyse ist fehlgeschlagen. Versuchen Sie es später noch einmal!',
  [T.datasetDeleteConfirmation]:
    'Sind Sie sicher, dass Sie das Dataset löschen wollen?',
  [T.datasetDeleteSuccess]: 'Dataset erfolgreich gelöscht!',
  [T.datasetDeleteFailed]:
    'Das Löschen von Datensätzen ist fehlgeschlagen. Versuchen Sie es später noch einmal!',
  [T.questionnaireDeleteConfirmation]:
    'Sind Sie sicher, dass Sie den Fragebogen löschen wollen?',
  [T.questionnaireDeleteSuccess]: 'Fragebogen erfolgreich gelöscht!',
  [T.questionnaireDeleteFailed]:
    'Die Löschung des Fragebogens ist fehlgeschlagen. Versuchen Sie es später noch einmal!',
  [T.addQuestionnaire]: 'Neuen Fragebogen hinzufügen',
  [T.addQuestion]: 'Frage hinzufügen',
  [T.questionnaireTitlePlaceholder]: 'Titel des Fragebogens eingeben',
  [T.questionnaireQuestionPlaceholder]: 'Frage eingeben',
  [T.questionnaireCreationSuccess]: 'Fragebogen erfolgreich erstellt.',
  [T.questionnaireCreationFailure]:
    'Die Erstellung des Fragebogens ist fehlgeschlagen.',
  [T.questionnaireUpdateFailure]: 'Fragebogen Update fehlgeschlagen.',
  [T.datasetCorrupted]: 'Quelldatei gelöscht',
  [T.selectItemsToImport]: 'Elemente zum Importieren auswählen',
  [T.importFilesDisclaimer]: 'Sie können 10 Elemente auswählen',
  [T.analysisCancelSuccess]: 'Analyse erfolgreich abgebrochen!',
  [T.questionUpdatedSuccess]: 'Fragebogen erfolgreich aktualisiert',
  [T.maximumDatasetSize]: 'Maximal 10 Dateien in einem Dataset erlaubt',
  [T.minimumDatasetSize]:
    'Mindestens 1 Datei ist erforderlich, um einen Datensatz zu erstellen',
  [T.questionnaireBlocked]:
    'Fragebogen kann aufgrund der Assoziation nicht bearbeitet werden.',
  [T.analysisFailed]: 'Dokument Analyse fehlgeschlagen',
  [T.analysisResourceFailure]:
    'Fehler Dokumentanalyse Ressource nicht gefunden',
  [T.analysisLimit]:
    'Limit für laufende Analysen erreicht! Bitte warten Sie, da gerade eine andere Analyse durchgeführt wird.',
  [T.analysisEditingBlocked]:
    'Die Bearbeitung der Analyse ist blockiert. Bitte warten Sie, während die laufende Analyse abgeschlossen wird.',
  [T.closeAnalysisTitle]: 'Antwort abbrechen/ Im Hintergrund laufen lassen',
  [T.cancelAnalysis]: 'Analyse abbrechen',
  [T.runInBackground]: 'Im Hintergrund ausführen',
  [T.inProgressAnalysis]: 'Analyse läuft',
  [T.inProgressAnalysisDescription]:
    'Möchten Sie die Analyse abbrechen oder im Hintergrund ausführen?',
  [T.regeneratingResponse]: 'Regenerierende Reaktion',
  [T.updateQuestionnaire]: 'Fragebogen aktualisieren',
  [T.updateAssociatedQuestionnaireDescription]:
    'Fragebogen mit vorheriger Verknüpfung kann nicht aktualisiert werden.',
  [T.newQuestionnaireDescription]:
    'Speichern Sie eine neue Kopie des Fragebogens.',
  [T.saveNew]: 'Neu speichern',
  [T.analysisQuestionnaireUpdateLimit]:
    'Fragebögen, die mit anderen Analysen verbunden sind, können nicht aktualisiert werden.',
  [T.newAnalysisTooltip]: 'Analyse ist bereits im Gange',
  [T.analysisDetail]: 'Analyse Detail',
  [T.selectDataset]: 'Datensatz auswählen',
  [T.selectQuestionnaire]: 'Selezionare il questionario',
  [T.docAnalystDuplicationError]:
    'Es existiert bereits ein Artikel mit demselben Titel.',
  [T.edited]: 'Bearbeitet',
  [T.generating]: 'Generieren',
  [T.updateQuestionnairePending]: 'Aktualisierung Fragebogen ausstehend',
  [T.updateQuestionnairePendingDescription]:
    'Bitte aktualisieren Sie den Fragebogen vor dem Verlassen.',
  [T.dateCreated]: 'Datum der Erstellung',
  [T.clearAll]: 'Alle löschen',
  [T.questionnaireAssociationMsg]: `Sie können den Fragebogen aufgrund seiner Verbindung mit der Analyse nicht bearbeiten.`,
  [T.questionnaireName]: 'Fragebogenname',
  [T.enterQuestionnaireName]: 'Fragebogennamen eingeben',
  [T.updateQuestionnaireDescription]:
    'Fragebogen ist aktualisiert. Klicken Sie auf „Aktualisieren“, um die Änderungen zu speichern.',
  [T.updateQuestionnaireDialogTitle]: 'Änderungen im Fragebogen speichern',
  [T.createdOn]: 'Erstellt am',
  [T.datasetDeletedTooltip]:
    'Der Originaldatensatz wurde vom Eigentümer gelöscht / geändert.',
  [T.mainFeatures]: 'Hauptfunktionen',
  [T.docAnalystFeatures]:
    'Präzise Antworten aus Unternehmensdokumenten extrahieren$$Zweckgerichtete Fragebögen erstellen$$Wissensdatenbanken mühelos verknüpfen$$Gründliche Analysen durchführen$$Fragen ändern und Analysen nach Bedarf regenerieren$$Aktive Zusammenarbeit mit Teammitgliedern',
  [T.docClassifierFeatures]:
    'Analysiert Textdaten aus einer Vielzahl von Unternehmensdokumenten, um zentrale Themen, Themen oder Kategorien zu identifizieren$$Klassifiziert und sortiert Dokumente in benutzerdefinierte Kategorien ohne manuelles Eingreifen$$Verarbeitet effizient große Mengen von Dokumenten und sorgt für eine konsistente Klassifizierung',
  [T.docSummaryGenerator]:
    'Erstellt prägnante Zusammenfassungen aus umfangreichen Dokumenten$$Identifiziert und hebt die wichtigsten Informationen hervor$$Ermöglicht es den Benutzern, die Länge der Zusammenfassung nach ihren Bedürfnissen anzupassen$$Fasst mehrere Dokumente gleichzeitig zusammen, um einen Vergleich oder Überblick zu ermöglichen$$Vereinfacht die Inhaltsüberprüfung und verbessert das Verständnis$$Unterstützt die Zusammenfassung in mehreren Sprachen und berücksichtigt unterschiedliche Dokumente',
  [T.model]: 'Modell',
  [T.fromDate]: 'Von Datum',
  [T.sendQuestionnaire]: 'Fragebogen senden',
  [T.sentTo]: 'Gesendet an',
  [T.questionnaireSendSuccess]: 'Fragebogen erfolgreich gesendet',
  [T.receivedFrom]: 'Erhalten von',
  [T.questionnaireNotSharedYet]: 'Noch nicht mit jemandem geteilt.',
  [T.sentHistory]: "Gesendete Geschichte",
  [T.byMe]: 'Von mir',
  [T.all]: 'Alle',
  [T.filesDeletedInDatasetDisclaimer]: 'Einige Dateien wurden gelöscht.',
  [T.allFilesDeletedInDatasetDisclaimer]:
    'Alle Dateien im Datensatz wurden gelöscht.',
  [T.fileName]: 'Dateiname',
  [T.received]: 'Empfangen',

  // Document Analysis statuses
  [T.L_DA_CM]: 'Abgeschlossen',
  [T.L_DA_IP]: 'Inprogress',
  [T.L_DA_CN]: 'Abgesagt',
  [T.L_DA_FL]: 'Gescheitert',
  [T.L_DA_PC]: 'Teilweise fertiggestellt',
  [T.L_DA_RGR]: 'Regenerierung',

  // Assistants
  [T.assistants]: 'Assistenten',
  [T.allAssistant]: 'Alle',
  [T.createdByMe]: 'Von mir erstellt',
  [T.creator]: 'Ersteller',
  [T.createAssistant]: 'Assistent erstellen',
  [T.updateAssistant]: 'Assistent aktualisieren',
  [T.assistantName]: 'Assistentenname',
  [T.assistantDescription]: 'Assistentenbeschreibung',
  [T.assistantInstructions]: 'Assistentenanweisungen',
  [T.assistantModal]: 'Assistentenmodal',
  [T.assistantCreatedSuccessfully]: 'Assistent erfolgreich erstellt',
  [T.assistantUpdatedSuccessfully]: 'Assistent erfolgreich aktualisiert',
  [T.assistantDeletedSuccessfully]: 'Assistent erfolgreich gelöscht',
  [T.iconUploadedSuccessfully]: 'Symbol erfolgreich hochgeladen',
  [T.namePlaceholder]: 'Geben Sie Ihrem Assistenten einen Namen',
  [T.descriptionPlaceholder]:
    'Fügen Sie eine kurze Beschreibung über diesen Assistenten hinzu.',
  [T.instructions]: 'Anweisungen',
  [T.instructionsPlaceholder]:
    'Was macht dieser Assistent? Wie sollte er sich verhalten? Was sollte er vermeiden?',
  [T.conversationStarters]: 'Gesprächsanfänge',
  [T.conversationStartersErrorMessage]:
    'Gesprächsanfänge dürfen nicht leer sein',
  [T.conversationStartersPlaceholder]:
    'Beispielaufforderungen für den Benutzer, um das Gespräch zu beginnen.',
  [T.aiModels]: 'KI-Modelle',
  [T.aiModelsPlaceholder]: 'Wählen Sie ein beliebiges KI-Modell',
  [T.knowledgeAddFiles]: 'Unternehmenswissen (Dateien hinzufügen)',
  [T.addFiles]: 'Dateien hinzufügen',
  [T.knowledgeAddFilesAdded]: '{{totalFiles}}/50 Dateien hinzugefügt',
  [T.enterpriseKnowledge]: 'Unternehmenswissen',
  [T.worldKnowledgeWithWebSearch]: 'Weltwissen mit Websuche',
  [T.conversationStartersCount]: '{{totalCon}}/4 hinzugefügt',
  [T.preview]: 'Vorschau',
  [T.chooseEmojis]: 'Bild auswählen',
  [T.keepItPrivate]: 'Halten Sie es privat',
  [T.emojiDisclaimer]:
    'Um ein Emoji hochzuladen, sollte es weniger als 500 Kb groß sein',
  [T.assistantFilesPermission]:
    'Sie haben keinen Zugriff auf die folgenden Dateien',
  [T.assistantNoFiles]:
    'Die Wissensdateien des Assistenten für Unternehmen wurden gelöscht',
  [T.noFilesDisclaimer]:
    'Dateien, die zum Wissen des Assistenten hinzugefügt wurden, sind aus den Arbeitsbereichen gelöscht worden. Assistant-Controller können neue Dateien hinzufügen oder die Fähigkeiten des Assistant ändern, um einen neuen Chat mit ihm zu starten.',
  [T.addFileDisclaimer]:
    'Stellen Sie sicher, dass hinzugefügte Dateien im Wissen für Benutzer zugänglich sind, mit denen sie geteilt werden',
  [T.errorConfirmationDialog]:
    'Bitte beheben Sie die folgenden Fehler, bevor Sie fortfahren',
  [T.assistantDeletedDisclaimer]:
    'Sie können den Chat mit einem gelöschten Assistenten nicht fortsetzen.',
  [T.outdatedVersionText]: `Sie können mit einer veralteten Version dieses Assistenten nicht weiter chatten. Starten `,
  [T.newChat]: 'neuer Chat',
  [T.updatedAssistant]: ` mit dem aktualisierten Assistenten.`,
  [T.assistantNameError]: 'Assistent mit demselben Namen existiert bereits',
  [T.noAiModelDisclaimer]:
    'Das KI-Modell des Assistenten wurde vom Administrator deaktiviert. Assistenten-Kontrolleure können ihm entweder ein neues Modell zuweisen oder den Administrator-Zugriff auf das deaktivierte Modell anfordern, um einen neuen Chat zu starten.',
  [T.noAiModelTitle]: 'Konfiguriertes AI-Modell deaktiviert',
  [T.stayOnThisPage]: `Auf dieser Seite bleiben`,
  [T.aiModelErrorMessage]: `Bitte wählen Sie ein AI-Modell aus`,
  [T.createUpdateConfirmationDialogTitle]: `Nicht gespeicherte Änderungen`,
  [T.leaveWithoutSaving]: `Ohne Speichern verlassen`,
  [T.createUpdateConfirmationDisclaimer]: `Sie sind dabei, diese Seite mit nicht gespeicherten Änderungen zu verlassen.`,
  [T.addImage]: `Bild hinzufügen`,
  [T.changeImage]: `Bild ändern`,
  [T.close]: 'Schließen Sie',
  [T.assistantDeleted]: 'Assistentin Gelöscht',
  [T.conversationStarterErrorMessage]:
    'Assistent Starter darf nicht länger als 300 Zeichen sein',
  [T.viewMore]: 'Mehr sehen',
  [T.viewLess]: 'Weniger sehen'
};

export default deJSON;
